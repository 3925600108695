import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import {
  MEMBERS_PROFILE_APP_ID,
  MEMBERS_PROFILE_SECTION_ID,
  MEMBERS_PROFILE_WIDGET_ID,
  SANTA_MEMBERS_APP_ID,
} from '../../../../editor/app-config';

export async function getProfilePageUrls(
  flowAPI: ControllerFlowAPI,
  profiles: { id?: string; slug?: string }[] = [],
): Promise<{ [id: string]: string }> {
  const { isEditor } = flowAPI.environment;
  const memberUrls = {};
  try {
    if (!isEditor) {
      const membersApi =
        await flowAPI.controllerConfig.wixCodeApi.site.getPublicAPI(
          SANTA_MEMBERS_APP_ID,
        );
      await Promise.all(
        profiles.map(async ({ id, slug }) => {
          if (id && slug) {
            memberUrls[id] = await membersApi.getSectionUrl({
              appDefinitionId: MEMBERS_PROFILE_APP_ID,
              sectionId: MEMBERS_PROFILE_SECTION_ID,
              widgetId: MEMBERS_PROFILE_WIDGET_ID,
              memberId: id,
              memberSlug: slug,
            });
          }
        }),
      );
    }
  } catch (error) {
    console.error('Error on getting profileUrl:', error);
  }
  return memberUrls;
}
