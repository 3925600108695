import { IQuizContext } from './QuizContext';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { FormValues } from '@wix/form-viewer/widget';
import { initFormController } from '@wix/form-viewer/controller';
import {
  createQuizSubmission,
  getQuizSubmission,
} from '@wix/ambassador-achievements-quizzes-v1-quiz-submission/http';
import { GetQuizSubmissionResponse } from '@wix/ambassador-achievements-quizzes-v1-quiz-submission/types';
import { getFormSubmissionValues, getSubmissionAnswers } from './utils';
import { handleError } from '../ErrorHandler/errorHandlerPropsMap';

const WIX_FORMS_NAMESPACE = 'wix.achievements.quizzes.v1.quiz';

type SetQuizProps = (patch: Partial<IQuizContext>) => void;

export const quizPropsMap = (
  flowAPI: ControllerFlowAPI,
): Partial<IQuizContext> => {
  const setProps: SetQuizProps = flowAPI.controllerConfig.setProps;

  return {
    controllerIsReady: false,
    initController: async (formId: string) => {
      setProps({
        controllerIsReady: false,
        lastSubmissionId: null,
        submission: null,
        sendSubmissionInProgress: false,
      });

      await initFormController(flowAPI as any, {
        formId,
        namespace: WIX_FORMS_NAMESPACE,
      });

      setProps({
        controllerIsReady: true,
      });
    },
    sendSubmission: async (quizId: string, values: FormValues) => {
      // from submission values to quiz answers
      const answers = {};
      Object.keys(values).forEach((key) => {
        answers[key] = {
          value: values[key],
        };
      });

      setProps({
        sendSubmissionInProgress: true,
      });

      try {
        const sbmsn = await flowAPI.httpClient.request(
          createQuizSubmission({
            submission: {
              quizId,
              answers,
            },
          }),
        );

        // This is needed for seamless experience at UI between two subsequent requests.
        flowAPI.controllerConfig.setProps({
          isResolveStepRequestInProgress: true,
        });

        setProps({
          sendSubmissionInProgress: false,
          lastSubmissionId: sbmsn?.data?.submission?.id,
          submission: {
            submissions: getFormSubmissionValues(sbmsn?.data?.submission),
            answers: getSubmissionAnswers(sbmsn?.data?.submission),
          },
          earnedGrade: sbmsn?.data?.submission?.earnedGrade,
        });
      } catch (error) {
        setProps({
          sendSubmissionInProgress: false,
          lastSubmissionId: null,
          submission: null,
          earnedGrade: null,
        });

        handleError({ error, context: 'quizSubmission' });
      }
    },
    // If inside step data we have `quizSubmissionId` --> FormQuiz component calls `getSubmission` with `submissionId`.
    getSubmission: async (submissionId: string) => {
      setProps({
        getSubmissionInProgress: true,
      });

      try {
        const sbmsn: GetQuizSubmissionResponse = (
          await flowAPI.httpClient.request(
            getQuizSubmission({
              submissionId,
            }),
          )
        )?.data;

        setProps({
          submission: {
            submissions: getFormSubmissionValues(sbmsn?.submission),
            answers: getSubmissionAnswers(sbmsn?.submission),
          },
          earnedGrade: sbmsn?.submission?.earnedGrade,
          getSubmissionInProgress: false,
        });
      } catch (error) {
        setProps({
          submission: null,
          earnedGrade: null,
          getSubmissionInProgress: false,
        });

        handleError({ error, context: 'getQuizSubmission' });
      }
    },
    resubmitTheQuiz: () => {
      setProps({
        lastSubmissionId: null,
        submission: null,
        earnedGrade: null,
      });
    },
    clearQuizStore: () => {
      setProps({
        controllerIsReady: false,
        lastSubmissionId: null,
        submission: null,
        sendSubmissionInProgress: false,
        earnedGrade: null,
      });
    },
    setPagesData: (pagesData) => {
      setProps({
        pagesData,
      });
    },
  };
};
