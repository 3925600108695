import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { Participant } from '@wix/ambassador-challenges-v1-participant/types';
import { isForcedPreviewVisitor } from '../../../selectors/isForcedPreview';
import { request } from '../../../services/request';
import { myProgram } from '@wix/ambassador-challenges-v1-participant/http';
import { getChallengeSlugFromLocation } from '../../Location/locationProviderPropsMap';
import { isMA } from '../../../selectors/isMA';

export async function getParticipant(
  flowAPI: ControllerFlowAPI,
): Promise<Participant> {
  /*
    Inside the editor user can't be a participant.
    Reject request for participant if owner wants to preview the Visitor page from BM.
    Check for MA.
   */
  if (
    isForcedPreviewVisitor(
      flowAPI?.controllerConfig?.wixCodeApi?.location?.query,
    ) ||
    isMA(flowAPI) ||
    flowAPI.environment.isEditor
  ) {
    return null;
  }

  // const user = getUserFromConfig(flowAPI.controllerConfig);
  const challengeId = getChallengeSlugFromLocation(flowAPI);

  if (challengeId) {
    try {
      const myProg = (
        await request(
          flowAPI,
          myProgram({
            programId: challengeId,
          }),
        )
      )?.data;

      if (myProg?.participant) {
        return myProg.participant;
      }

      return null;
    } catch (err) {
      console.error('[Challenges]: Error at get participant request.');
      console.error(err);
    }
  }

  return null;
}
