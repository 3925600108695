import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import {
  State as ParticipantState,
  Participant,
} from '@wix/ambassador-challenges-v1-participant/types';
import { acceptInvite } from './acceptInvite';
import { sendRequestForOwnersApprove } from './sendRequestForOwnersApprove';
import { joinWithoutOwnerApprove } from './joinWithoutOwnerApprove';
import { IUserProviderProps } from '../UserProvider';
import { ProgramAccessType } from '../../../types/programs';
import { getChallengeData } from '../../storage-contexts/Challenge';

export const createParticipant = async ({
  timeZone,
  startDateFormatted,
  flowAPI,
  userProvider,
}: {
  timeZone: string;
  userProvider: IUserProviderProps;
  startDateFormatted?: string;
  flowAPI: ControllerFlowAPI;
}): Promise<Participant> => {
  const participant = userProvider.participant;
  const participantTransitionState: ParticipantState | undefined =
    participant?.transitions?.length && participant?.transitions['0'].state;
  const challengeData = await getChallengeData(flowAPI);
  const challengeAccessType =
    challengeData.challenge?.settings?.accessRestrictions?.accessType;
  let _participant = null;

  switch (participantTransitionState) {
    case ParticipantState.INVITED:
      await acceptInvite(flowAPI, timeZone, startDateFormatted);
      await userProvider.updateParticipant();

      return userProvider.participant;
    case ParticipantState.PAYMENT_REQUESTED:
    case ParticipantState.PAYMENT_STARTED:
    case ParticipantState.JOIN_REQUESTED:
    case ParticipantState.JOINED:
    case ParticipantState.RUNNING:
    case ParticipantState.COMPLETED:
    case ParticipantState.FAILED:
    case ParticipantState.SUSPENDED:
      return userProvider.participant;
    case ParticipantState.JOIN_REJECTED:
    case ParticipantState.REMOVED:
    case ParticipantState.LEFT:
    default: // todo: check: remove when margo will fix issue
      if (challengeAccessType === ProgramAccessType.PRIVATE) {
        _participant = await sendRequestForOwnersApprove(
          flowAPI,
          timeZone,
          startDateFormatted,
        );
      } else {
        _participant = await joinWithoutOwnerApprove(
          flowAPI,
          timeZone,
          startDateFormatted,
        );
      }

      await userProvider.updateParticipant(_participant);
      return userProvider.participant;
  }
};
