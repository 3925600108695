import { IProvidersProps } from './interfaces';
import { locationProviderPropsMap } from '../Location/locationProviderPropsMap';
import { generalDataProviderPropsMap } from '../GeneralDataProvider/generalDataProviderPropsMap';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { loadingPropsMap } from '../Loading/loadingPropsMap';
import { errorHandlerPropsMap } from '../ErrorHandler/errorHandlerPropsMap';
import { localStoragePropsMap } from '../LocalStorage/localStoragePropsMap';
import { monitoringPropsMap } from '../Monitoring/MonitoringPropsMap';
import { quizPropsMap } from '../Quiz/QuizPropsMap';
import { toastPropsMap } from '../ToastContext/toastPropsMap';

interface IBaseProviders {
  flowAPI: ControllerFlowAPI;
}

export const arrayToObject = (array) =>
  array.reduce((obj, item) => {
    return { ...obj, ...item };
  }, {});

export const arrayToObjectAsync = async (array) => {
  return arrayToObject(
    await Promise.all(array.filter((item) => item !== null)),
  );
};

export async function getBaseProvidersData({
  flowAPI,
}: IBaseProviders): Promise<Partial<IProvidersProps>> {
  const syncProvidersData = {
    ...locationProviderPropsMap(flowAPI),
    ...loadingPropsMap(flowAPI),
    ...localStoragePropsMap(flowAPI),
    ...monitoringPropsMap(flowAPI),
    ...quizPropsMap(flowAPI),
    ...toastPropsMap(flowAPI),
  };

  const asyncParallelProviders: Promise<any>[] = [
    generalDataProviderPropsMap(flowAPI),
    errorHandlerPropsMap(flowAPI),
  ];

  const asyncProvidersData = arrayToObject(
    await Promise.all(asyncParallelProviders),
  );

  return { ...syncProvidersData, ...asyncProvidersData };
}
