import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { IPaidPlansContext } from './interfaces';
import { isPaidPlansInstalled } from './helpers/isPaidPlansInstalled';
import { loadUserPaidPlans } from './api/loadPaidPlans';
import { userProviderPropsMap } from '../../User/userProviderPropsMap';
import { isUserJoinedAlreadyWithoutSuspended } from '../../User/helpers/userTypeHandlers';
import handlePaidPlansAfterLogin from './helpers/handlePaidPlansAfterLogin';
import { getUserEligiblePlans } from './helpers/getUserEligiblePlans';

let PAID_PLANS_DATA: IPaidPlansContext = null;

export const getPaidPlansData = async (
  flowAPI: ControllerFlowAPI,
): Promise<IPaidPlansContext> => {
  const userType = (await userProviderPropsMap(flowAPI)).userType;
  const isPPInstalled = await isPaidPlansInstalled(flowAPI);

  if (!isPPInstalled) {
    PAID_PLANS_DATA = {
      userPaidPlans: [],
      eligiblePlans: [],
    };
  }

  if (PAID_PLANS_DATA) {
    return PAID_PLANS_DATA;
  }

  if (isUserJoinedAlreadyWithoutSuspended(userType)) {
    return {
      userPaidPlans: [],
      eligiblePlans: [],
    };
  }

  try {
    const [userPaidPlans, eligiblePlans] = await Promise.all([
      loadUserPaidPlans(flowAPI),
      getUserEligiblePlans(flowAPI),
    ]);

    PAID_PLANS_DATA = {
      userPaidPlans,
      eligiblePlans,
    };
  } catch (err) {
    console.error(err);
  }

  return PAID_PLANS_DATA;
};

export const getPaidPlansInitialData = async (
  flowAPI: ControllerFlowAPI,
): Promise<IPaidPlansContext> => {
  const paidPlans = await getPaidPlansData(flowAPI);

  void handlePaidPlansAfterLogin();

  return paidPlans;
};

export const getPaidPlansInitialDataForList = async (
  flowAPI: ControllerFlowAPI,
): Promise<Pick<IPaidPlansContext, 'userPaidPlans'>> => {
  const isPPInstalled = await isPaidPlansInstalled(flowAPI);

  if (!isPPInstalled) {
    return {
      userPaidPlans: [],
    };
  }

  const userPaidPlans = await loadUserPaidPlans(flowAPI);

  return {
    userPaidPlans,
  };
};

export const resetPaidPlansCache = () => {
  PAID_PLANS_DATA = null;
};
