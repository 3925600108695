import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { getChallengeData } from '../storage-contexts/Challenge';
import { userProviderPropsMap } from '../User/userProviderPropsMap';
import { isUserJoinedAlready } from '../User/helpers/userTypeHandlers';
import { getDescription, setDescription } from '../../selectors/step';
import { request } from '../../services/request';
import { handleError } from '../ErrorHandler/errorHandlerPropsMap';
import { ensureDetails, isRequiredOwnerData } from './helpers';
import { ParticipantStep } from '../../types/v3Types';
import { DescriptionFieldSet } from '@wix/ambassador-challenges-v1-challenge/types';
import { getStep as getParticipantStep } from '@wix/ambassador-challenges-v1-participant/http';
import { getStep } from '@wix/ambassador-challenges-v1-challenge/http';
import { isV3enabled } from '../../experiments/isV3enabled';
import { requestStepV3 } from '../storage-contexts/Challenge/api/requestStepV3';

export async function requestParticipantStep(
  stepId: string,
  steps: ParticipantStep[],
  flowAPI: ControllerFlowAPI,
): Promise<void> {
  // here we assume, that challenge, participant and sections / steps already loaded
  const challengeData = await getChallengeData(flowAPI);
  const challenge = challengeData?.challenge || null;
  const participant = (await userProviderPropsMap(flowAPI))?.participant;
  // const steps = (await participantSectionsPropsMap(flowAPI))?.participantSteps
  //   ?.steps;
  const isJoinedParticipant = isUserJoinedAlready(
    participant?.transitions?.[0]?.state,
  );
  const isPreviewWithParticipant =
    flowAPI.environment.isPreview && isJoinedParticipant;
  const isOwnerData = isRequiredOwnerData(flowAPI) && !isPreviewWithParticipant;

  if (!challenge || !(isOwnerData || participant) || !steps) {
    console.error("Can't request participant step, not enough data.", {
      challenge,
      participant,
      steps,
      isOwnerData,
    });
    return;
  }

  const stepToUpdate = steps.find((step) => {
    return step?.id === stepId;
  });
  let description: any = null;

  if (!stepToUpdate) {
    console.error("Can't find participant step for update.");
    return;
  }
  let step: ParticipantStep;
  if (isV3enabled(flowAPI)) {
    step = await requestStepV3(
      { stepId, descriptionFieldSet: DescriptionFieldSet.EXTENDED },
      flowAPI,
    );
    description = getDescription(step);
  } else if (!isOwnerData) {
    try {
      step = (
        await request(
          flowAPI,
          getParticipantStep({
            challengeId: challenge.id,
            participantId: participant.id,
            stepId,
            descriptionFieldSet: DescriptionFieldSet.EXTENDED,
          }),
        )
      )?.data?.step;
      description = ensureDetails(step?.source?.settings?.general?.description);
    } catch (error) {
      console.error(error);
      handleError({ error, context: 'requestParticipantStep (participant)' });
    }
  } else {
    try {
      const step = (
        await request(
          flowAPI,
          getStep({
            challengeId: challenge.id,
            stepId,
            descriptionFieldSet: DescriptionFieldSet.EXTENDED,
          }),
        )
      )?.data?.step;

      description = ensureDetails(step?.settings?.general?.description);
    } catch (error) {
      console.error(error);
      handleError({ error, context: 'requestParticipantStep (challenge)' });
    }
  }

  setDescription(stepToUpdate.source, description);

  flowAPI.controllerConfig.setProps({
    participantSteps: { steps },
  });
}
