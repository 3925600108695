import { isV3Participant } from './index';
import { Participant, ParticipantV1 } from '../../types/v3Types';

export function incrementProgress(participant: Participant, progress?: number) {
  if (!participant) {
    return;
  }
  if (isV3Participant(participant)) {
    throw new Error('Not implemented');
  }
  const participantV1 = participant as ParticipantV1;
  if (participantV1?.stepsSummary) {
    participantV1.stepsSummary.completedStepsNumber =
      participantV1.stepsSummary.completedStepsNumber + 1;
  }

  return participantV1;
}
