import { ControllerFlowAPI, IWixWindow } from '@wix/yoshi-flow-editor';

export class WarmupData {
  private _warmupData: IWixWindow['warmupData'];
  private flowApi: ControllerFlowAPI;

  constructor(flowAPI: ControllerFlowAPI) {
    this.flowApi = flowAPI;

    this._warmupData = flowAPI.controllerConfig.wixCodeApi.window.warmupData;
  }

  get(key: string) {
    const isWarmupDataDisabled = this.flowApi.experiments.enabled(
      'specs.programs.OOIDisableWarmupData',
    );

    return this.flowApi.environment.isSSR || isWarmupDataDisabled
      ? undefined
      : this._warmupData.get(key);
  }

  set(key: string, data: unknown) {
    const isWarmupDataDisabled = this.flowApi.experiments.enabled(
      'specs.programs.OOIDisableWarmupData',
    );

    if (!isWarmupDataDisabled && this.flowApi.environment.isSSR) {
      this._warmupData.set(key, data);
    }
  }
}
