import { ChallengeServerlessData } from '../../../../types/challenge-serverless';
import {
  getMockedChallenge,
  isMockedChallenge,
} from '../../../main/getMockedChallenges';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { Referrer } from '../../../storage/referrer';
import { getFromMemoryStorage } from '../../../storage/storage';
import { getInstance } from '../../../../services/instance';
import { locationProviderPropsMap } from '../../../Location/locationProviderPropsMap';

export default async function requestChallenge(
  slugOrChallengeId: string,
  flowAPI: ControllerFlowAPI,
  referrer?: Referrer,
): Promise<ChallengeServerlessData> {
  const location = locationProviderPropsMap(flowAPI);
  const isParticipantPage = location?.path?.includes('participant-page');

  if (!slugOrChallengeId) {
    return null;
  }

  if (isMockedChallenge(slugOrChallengeId, flowAPI)) {
    const mockedChallenge = getMockedChallenge(slugOrChallengeId, flowAPI);

    return { challenge: mockedChallenge, badgesData: { badges: [] } };
  }

  const instanceFromUrl = getInstance(flowAPI);

  const serverlessResponse = await getFromMemoryStorage(
    flowAPI.controllerConfig,
    {
      key: `requestChallenge:${slugOrChallengeId}`,
      getter: async () => {
        return flowAPI.httpClient.get<ChallengeServerlessData>(
          '/_serverless/challenges-web-serverless/get-challenge',
          {
            params: {
              slugOrChallengeId,
              withoutInstructors: isParticipantPage ? 'true' : undefined,
            },
            headers: {
              Authorization: instanceFromUrl,
            },
          },
        );
      },
      referrer: referrer || Referrer.ALL,
    },
  );

  // @ts-expect-error
  if (serverlessResponse?.data?.errorMessage) {
    // @ts-expect-error
    console.error(serverlessResponse.data.errorMessage);
  }

  return serverlessResponse?.data;
}
