import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { getChallengeData } from '../storage-contexts/Challenge';
import { userProviderPropsMap } from '../User/userProviderPropsMap';
import { isUserJoinedAlready } from '../User/helpers/userTypeHandlers';
import { request } from '../../services/request';
import { handleError } from '../ErrorHandler/errorHandlerPropsMap';
import { ensureDetails, isRequiredOwnerData } from './helpers';
import {
  ParticipantSection,
  DescriptionFieldSet,
} from '@wix/ambassador-challenges-v1-participant/types';
import { getSection as getParticipantSection } from '@wix/ambassador-challenges-v1-participant/http';
import { getSection } from '@wix/ambassador-challenges-v1-challenge/http';
import { isV3enabled } from '../../experiments/isV3enabled';
import { requestSectionV3 } from '../storage-contexts/Challenge/api/requestChallengeSections';
import {
  getSectionDescription,
  setDescription,
} from '../../selectors/sections';

export async function requestParticipantSection(
  sectionId: string,
  sections: ParticipantSection[],
  flowAPI: ControllerFlowAPI,
): Promise<void> {
  // here we assume, that challenge, participant and sections already loaded
  const challengeData = await getChallengeData(flowAPI);
  const challenge = challengeData?.challenge || null;
  const participant = (await userProviderPropsMap(flowAPI))?.participant;
  // const sections = (await participantSectionsPropsMap(flowAPI))
  //   ?.listParticipantSections;
  const isJoinedParticipant = isUserJoinedAlready(
    participant?.transitions?.[0]?.state,
  );
  const isPreviewWithParticipant =
    flowAPI.environment.isPreview && isJoinedParticipant;
  const isOwnerData = isRequiredOwnerData(flowAPI) && !isPreviewWithParticipant;
  if (!challenge || !(isOwnerData || participant) || !sections) {
    console.error("Can't request participant section, not enough data.", {
      challenge,
      participant,
      sections,
      isOwnerData,
    });
    return;
  }

  const sectionToUpdate = sections.find((section) => {
    return section?.id === sectionId;
  });
  let description: any = null;

  if (!sectionToUpdate) {
    console.error("Can't find participant section for update.");
  }

  if (isV3enabled(flowAPI)) {
    const section = await requestSectionV3({ sectionId }, flowAPI);
    description = getSectionDescription(section);
  } else if (!isOwnerData) {
    try {
      const section = (
        await request(
          flowAPI,
          getParticipantSection({
            challengeId: challenge.id,
            participantId: participant.id,
            sectionId,
            descriptionFieldSet: DescriptionFieldSet.EXTENDED,
          }),
        )
      )?.data?.section;

      description = ensureDetails(section?.source?.settings?.description);
    } catch (error) {
      console.error(error);
      handleError({
        error,
        context: 'requestParticipantSection (participant)',
      });
    }
  } else {
    try {
      const section = (
        await request(
          flowAPI,
          getSection({
            challengeId: challenge.id,
            sectionId,
            descriptionFieldSet: DescriptionFieldSet.EXTENDED,
          }),
        )
      )?.data?.section;

      description = ensureDetails(section?.settings?.description);
    } catch (error) {
      console.error(error);
      handleError({
        error,
        context: 'requestParticipantSection (challenge)',
      });
    }
  }

  setDescription(sectionToUpdate.source, description);

  flowAPI.controllerConfig.setProps({
    listParticipantSections: sections,
  });
}
