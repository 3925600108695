import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import {
  Challenge,
  DurationUnit,
  DescriptionFieldSet,
} from '@wix/ambassador-challenges-v1-challenge/types';
import { UpdateParticipantStepStatusPayload } from './ParticipantStepsContext';
import { userProviderPropsMap } from '../User/userProviderPropsMap';
import { isUserJoinedAlready } from '../User/helpers/userTypeHandlers';

import { handleError } from '../ErrorHandler/errorHandlerPropsMap';
import { isForcedPreviewParticipant } from '../../selectors/isForcedPreview';
import { isMockedChallenge } from '../main/getMockedChallenges';
import { toParticipantSteps } from './toParticipantSteps';
import { PARTICIPANT_STEPS } from '../../__mocks__/participantSteps';
import { getUrlParams } from '../Location/locationProviderPropsMap';
import { request } from '../../services/request';

import {
  ListParticipantStepsResponse,
  Participant,
  ParticipantStep,
  State,
} from '@wix/ambassador-challenges-v1-participant/types';
import { listSteps as listStepsChallenge } from '@wix/ambassador-challenges-v1-challenge/http';
import {
  listSteps,
  myProgramStep,
} from '@wix/ambassador-challenges-v1-participant/http';
import { patchParticipantStepList } from '@wix/challenges-web-library';
import { IParticipantSectionsContext } from '../ParticipantSections/ParticipantSectionsContext';
import { getChallengesListWithMocks } from '../storage-contexts/ChallengesList';
import {
  requestChallenge,
  resolveProgramId,
} from '../storage-contexts/Challenge';
import { isV3enabled } from '../../experiments/isV3enabled';

export const loadParticipantSteps = async (
  flowAPI: ControllerFlowAPI,
  program: Challenge,
): Promise<IParticipantSectionsContext['participantSteps']> => {
  const { isEditor, isPreview } = flowAPI.environment;
  const userProvider = await userProviderPropsMap(flowAPI);
  const { participant }: { participant?: Participant } = userProvider;

  const isJoinedParticipant = isUserJoinedAlready(
    participant?.transitions?.[0]?.state,
  );

  let participantSteps: ListParticipantStepsResponse = {
    steps: [],
  };

  flowAPI.controllerConfig.setProps({
    isParticipantStepsLoading: true,
  });

  if (isJoinedParticipant && userProvider.userType !== State.RUNNING) {
    try {
      const challengeId = program.id;

      if (!challengeId || program?.stepsSummary?.stepsNumber === 0) {
        return { steps: [] };
      }

      participantSteps = (
        await request(
          flowAPI,
          listSteps({
            challengeId,
            participantId: participant.id,
            descriptionFieldSet: DescriptionFieldSet.STANDARD,
          }),
        )
      )?.data;
    } catch (error) {
      handleError({ error, context: 'participantAPI.listSteps' });
    }
  }

  if (
    (isEditor ||
      isPreview ||
      isForcedPreviewParticipant(
        flowAPI?.controllerConfig?.wixCodeApi?.location?.query,
      )) &&
    !participantSteps?.steps?.length
  ) {
    const challengeId =
      (await resolveProgramId(flowAPI))?.programId ||
      (await getChallengesListWithMocks(flowAPI))?.memberChallenges?.[0]
        ?.challenge?.id;

    let steps;

    if (challengeId && !isMockedChallenge(challengeId, flowAPI)) {
      try {
        const challengeSteps = (
          await request(
            flowAPI,
            listStepsChallenge({
              challengeId,
              fromOffset: {
                value: 0,
                unit: DurationUnit.DAYS,
              },
              descriptionFieldSet: DescriptionFieldSet.STANDARD,
            }),
          )
        )?.data;

        steps = toParticipantSteps({ ownerSteps: challengeSteps });
      } catch (error) {
        handleError({ error, context: 'getOwnerListSteps' });
      }
    }

    participantSteps = {
      steps: steps || PARTICIPANT_STEPS,
    };
  }

  flowAPI.controllerConfig.setProps({
    isParticipantStepsLoading: false,
  });

  return {
    ...participantSteps,
    steps: patchParticipantStepList(participantSteps.steps),
  };
};
export const updateParticipantStepStatus = (
  flowAPI: ControllerFlowAPI,
  payload: UpdateParticipantStepStatusPayload,
): void => {
  const {
    stepId,
    steps,
    sections,
    replace,
    feedbackItems,
    quizSubmission,
    transitions,
  } = payload;
  const checkStep = (step) => {
    if (step.id === stepId) {
      if (transitions || replace) {
        step.transitions = transitions;
      }

      if (feedbackItems?.items || feedbackItems?.quiz || replace) {
        step.feedback = feedbackItems;
      }

      if (quizSubmission || replace) {
        step.quizSubmission = quizSubmission;
      }
    }
  };

  if (sections?.length) {
    sections.forEach((section) => {
      (section.steps || []).forEach(checkStep);
    });

    flowAPI.controllerConfig.setProps({
      listParticipantSections: sections,
    });
  } else if (steps?.length) {
    steps.forEach(checkStep);

    flowAPI.controllerConfig.setProps({
      participantSteps: { steps },
    });
  } else {
    console.error("Can't update participant step status.");
  }
};

async function getSelectedStep(loadedSteps: ParticipantStep[], flowAPI) {
  const { slug, navigationId } = getUrlParams(flowAPI);
  const originalStep = loadedSteps.find((step) => step.id === navigationId);
  if (originalStep || isV3enabled(flowAPI)) {
    return originalStep;
  }

  const response = await requestChallenge(slug, flowAPI);
  return (
    await request(
      flowAPI,
      myProgramStep({
        programStepId: navigationId,
        programId: response.challenge.id,
      }),
    )
  )?.data?.participantStep;
}

export async function getStepNavigationInfo(
  flowAPI,
  loadedSteps: ParticipantStep[],
): Promise<{
  selectedStep?: ParticipantStep;
}> {
  const { navigationType } = getUrlParams(flowAPI);
  if (navigationType === 'step') {
    const selectedStep = await getSelectedStep(loadedSteps, flowAPI);
    return { selectedStep };
  }
  return {
    selectedStep: undefined,
  };
}
