import type { ILocation } from '@wix/native-components-infra/dist/src/types/types';
import { ILocationProviderProps } from './LocationProvider';
import { ILocationContext, ScreenNamesByPageId } from './LocationContext';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import memoize from 'lodash/memoize';
import {
  appendQueryStringToUrl,
  generateQueryString,
  getGeneralTargetUrl,
  getProgramsTargetUrl,
} from './urlHelpers';
import { biChangePage } from '../../services/biHelpers';
import { navigateToProgramPage } from './helpers/generateLinksToProgramForList';

export interface ILocationProps extends ILocationContext {}

export function getLocationFromFlowAPI(flowAPI: ControllerFlowAPI): ILocation {
  return (flowAPI.controllerConfig.wixCodeApi.location || {
    path: [],
    query: {},
    url: '',
  }) as ILocation;
}

export const locationProviderPropsMap = memoize(
  (flowAPI: ControllerFlowAPI): ILocationProviderProps => {
    const location = getLocationFromFlowAPI(flowAPI);
    const query = location.query;
    const { isPreview } = flowAPI.environment;
    return {
      // @ts-expect-error
      location: {
        path: location.path,
        query: location.query,
        url: location.url,
        baseUrl: location.baseUrl,
      },
      baseUrl: location.baseUrl,
      path: [...location.path],
      query: {
        ...query,
      },
      // todo: should be refactored, more flexible / universal, because now can be used only for program pages
      // added appDefinitionId and created a different branch if this param is exist, but its fast decision just for now
      goToPage: async (options) => {
        const targetUrl = options.appDefinitionId
          ? await getGeneralTargetUrl(options, flowAPI)
          : await getProgramsTargetUrl(options, flowAPI);
        if (isPreview) {
          await flowAPI.controllerConfig.wixCodeApi.location.to(targetUrl);
        } else {
          if (!options.appDefinitionId) {
            await biChangePage(flowAPI.bi, ScreenNamesByPageId[options.pageId]);
          }
          const url = appendQueryStringToUrl({
            url: targetUrl,
            query: generateQueryString(options.queryParams),
          });
          flowAPI.controllerConfig.wixCodeApi.location.to(url);
        }
      },
      goToExternalUrl(url: string) {
        flowAPI.controllerConfig.wixCodeApi.location.to(url);
      },
      async navigateToProgramPage(options) {
        return navigateToProgramPage(flowAPI, options);
      },
    };
  },
);

export function getUrlParams(flowAPI: ControllerFlowAPI): {
  slug?: string;
  pageName: string;
  navigationType?: 'step' | 'section' | 'description';
  navigationId?: string;
} {
  if (flowAPI.environment.isViewer) {
    const location = locationProviderPropsMap(flowAPI);
    const [pageName, slug, navigationType, navigationId] = location.path;
    return {
      slug,
      pageName,
      navigationType: navigationType as any,
      navigationId,
    };
  }
  return {
    pageName: '',
    navigationId: '',
    navigationType: undefined,
    slug: '',
  };
}

const SLUG_BLACK_LIST = ['.', 'wix-thunderbolt'];

export function getChallengeSlugFromLocation(flowAPI: ControllerFlowAPI) {
  const { isViewer } = flowAPI.environment;
  const location = locationProviderPropsMap(flowAPI);
  let path = location.path;

  let slug: string;
  if (
    location.path.length <= 1 ||
    ['ChallengesList', 'ChallengesListPage'].includes(flowAPI.componentName)
  ) {
    return '';
  } else if (location.path.includes('challenges-web-ooi')) {
    // path in iframe editor and viewer is ["prod", "editor", "challenges-web-ooi", "1.1880.0", "ChallengesPage", <challenge id which is optional>]
    path = path.slice(5, 10);
  }
  if (isViewer) {
    slug = path[1];
  } else {
    slug = path[location.path.length - 1];
  }

  const isSlugInBlackList = SLUG_BLACK_LIST.some((item) => {
    return slug?.includes(item);
  });

  if (isSlugInBlackList) {
    // flowAPI.errorMonitor?.captureMessage(`Malformed slug: ${slug}`, {
    //   level: Severity.Error,
    //   tags: { location: 'getChallengeSlugFromLocation' },
    // });
    return null;
  }
  return slug;
}

export function isHomePage(flowAPI: ControllerFlowAPI) {
  const { isViewer } = flowAPI.environment;
  const { location } = flowAPI.controllerConfig.wixCodeApi;
  return isViewer && location.path.length === 0;
}
