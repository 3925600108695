import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { getLocationFromFlowAPI } from '../../Location/locationProviderPropsMap';
import requestChallenge from './api/requestChallenge';
import { getChallengeData } from './data';

let resolvedId = '';

export const resolveProgramId = async (
  flowAPI: ControllerFlowAPI,
): Promise<{
  programId: string;
  programData?: Awaited<ReturnType<typeof requestChallenge>>;
}> => {
  if (resolvedId) {
    return { programId: resolvedId };
  }
  const programId = getProgramIdFromQueryParams(flowAPI);
  if (programId) {
    return { programId };
  }
  const programData = await getChallengeData(flowAPI);
  resolvedId = programData?.challenge?.id;
  return { programId: programData?.challenge?.id };
};

function getProgramIdFromQueryParams(flowAPI: ControllerFlowAPI) {
  return getLocationFromFlowAPI(flowAPI).query?.programId;
}
