import {
  Description,
  ParticipantStep,
  ParticipantStepState,
  Step,
  StepV3WithDelay,
  StepWithDelay,
} from '../types/v3Types';
import type { ChallengeStep } from '@wix/ambassador-challenges-v1-participant/types';
import type { Step as StepV3 } from '@wix/ambassador-online-programs-v3-step/types';
import { StepType } from '@wix/ambassador-online-programs-v3-step/types';
import { isV3Step } from '../utils/isV3';
import { isVideoStep } from './video';

export const getDescription = (step: Step): Description => {
  if (step && isV3Step(step)) {
    return (step as StepV3).description || null;
  }
  return (step as ChallengeStep)?.settings?.general?.description;
};

export const getTitle = (step: Step) => {
  return getDescription(step)?.title;
};

export const getDetails = (step: Step) => {
  return getDescription(step)?.details;
};

export const getDelay = (step: StepWithDelay) => {
  if (step && isV3Step(step)) {
    return (step as StepV3WithDelay).delay;
  }

  return (
    ((step as ChallengeStep)?.settings?.embedding?.startConditions[0]?.delay
      ?.value as number) || 0
  );
};

export const getQuizId = (step: Step) => {
  if (isV3Step(step)) {
    return (step as StepV3)?.quizOptions?.id;
  }
  return (step as ChallengeStep)?.settings?.general?.quiz?.quizId;
};

export const isQuizStep = (step: Step) => {
  return !!getQuizId(step);
};

export const getStepType = (step: Step): StepType => {
  if (isV3Step(step)) {
    return (step as StepV3)?.stepType;
  }
  if (isQuizStep(step)) {
    return StepType.QUIZ;
  }
  if (isVideoStep(step)) {
    return StepType.VIDEO;
  }
  return StepType.ARTICLE;
};

export const getQuizSubmissionId = (step: ParticipantStep) => {
  return step?.quizSubmission?.quizSubmissionId;
};

export const isFailed = (resolved: ParticipantStep) => {
  return (
    resolved?.transitions?.[0]?.state === ParticipantStepState.FAILED || false
  );
};

export const setDescription = (step: Step, description: Description = {}) => {
  if (isV3Step(step)) {
    (step as StepV3).description = description;
  } else if ((step as ChallengeStep).settings?.general) {
    (step as ChallengeStep).settings.general.description = description;
  }
};
